<template>
  <div>
    <!-- NOTE - modal-modify-result-recalculate-price-class-booking -->
    <b-modal
      id="modal-modify-result-recalculate-price-class-booking"
      :title="$t('reservation.recalculatePriceFlight')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-50"
      centered
      no-close-on-backdrop
      size="lg"
      @show="handleShowModal"
    >

      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="center rounded-pill width-100 mr-2"
          @click="close()"
        >
          {{ $t('reservation.close') }}
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient mt-lg-25  border-0 px-2"
          pill
          :disabled="loading"
          @click="submitRecalculatePrice"
        >
          <span class="align-middle">
            <!-- {{ $t('reservation.confirm') }} -->
            Tính giá
          </span>
        </b-button>
      </template>

      <IAmOverlay
        :loading="loading"
        :spinner-variant="'info'"
      >
        <!-- ANCHOR Itineraries -->
        <b-card body-class="py-1 px-25 px-md-1">
          <div class="mb-25">
            Hành trình:
          </div>
          <div v-if="dataTrips">
            <b-card
              v-for="(trip, tripIndex) of dataTrips"
              :key="tripIndex"
              class="border"
              body-class="p-75"
            >
              <b-row
                v-for="(segment, segmentIndex) in trip"
                :key="segmentIndex"
                no-gutters
                :class="segmentIndex > 0 ? 'mt-1' : ''"
              >
                <b-col
                  cols="12"
                  class="text-dark font-weight-bold font-small-4"
                >
                  <p class="font-weight-bolder mb-0">
                    <span class="mr-50 text-nowrap font-medium-1">{{ `${segment.airline}${segment.flightNumber}` }}</span>
                    <span class="mr-50 text-nowrap font-medium-1">{{ `${segment.bookingClass.code}` }}</span>
                    <span class="mr-50 text-nowrap font-medium-1">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).dayMonth }}</span>
                    <span class="mr-50 text-nowrap font-medium-1">{{ `${segment.departure}${segment.arrival}` }}</span>
                    <span class="mr-1 text-nowrap font-medium-1">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).hourMin }} {{ convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).hourMin }}</span>
                    <span class="text-nowrap font-medium-1">{{ `${segment.airCraft}` }}</span>
                  </p>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-card>

        <!-- ANCHOR - Passenger -->
        <b-card
          v-if="paxLists"
        >
          <div class="mb-1">
            Số lượng hành khách:
          </div>
          <div class="d-flex align-items-center mb-50">
            <span
              class="mr-50"
              style="min-width: 90px;"
            >
              Người lớn:
            </span>
            <div class="d-flex">
              <b-form-input
                v-model="paxLists.adult"
                v-remove-non-numeric-chars
                style="max-width: 50px;"
                :maxlength="1"
                :disabled="modifyClassBooking"
                class="mx-75 font-weight-bolder font-medium-3"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mb-50">
            <span
              class="mr-50"
              style="min-width: 90px;"
            >
              Trẻ em:
            </span>
            <div class="d-flex">
              <b-form-input
                v-model="paxLists.child"
                style="max-width: 50px;"
                :maxlength="1"
                class="mx-75 font-weight-bolder font-medium-3"
                :disabled="modifyClassBooking"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mb-50">
            <span
              class="mr-50"
              style="min-width: 90px;"
            >
              Em bé:
            </span>
            <div class="d-flex">
              <b-form-input
                v-model="paxLists.infant"
                style="max-width: 50px;"
                :maxlength="1"
                class="mx-75 font-weight-bolder font-medium-3"
                :disabled="modifyClassBooking"
              />
            </div>
          </div>
        </b-card>

        <!-- ANCHOR - BargainFinder -->
        <b-card body-class="py-1">
          <div
            class="margin-checkbox-label d-flex-center justify-content-around gap-2 text-center"
          >
            <b-form-checkbox
              v-model="isBargainFinder"
              name="retain-checkbox"
              class="custom-control-success"
            >
              <span
                class="mb-0 font-weight-bold"
                :class="isBargainFinder ? 'text-success' : 'text-dark'"
              >
                {{ $t('reservation.bargainFinder') }}
              </span>
            </b-form-checkbox>
          </div>
        </b-card>
      </IAmOverlay>
    </b-modal>

    <!-- NOTE - modal-modify-booking-recalculate-show-price-result  -->
    <b-modal
      id="modal-modify-booking-recalculate-show-price-result"
      :title="$t('reservation.showCalculatePrice.title')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      body-class="px-1"
      centered
      no-close-on-backdrop
      size="xl"
      @hide="handleHideModalShowPrice"
    >
      <template #modal-footer="{close}">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          size="md"
          variant="outline-secondary"
          class="rounded-pill px-1 mx-50 mr-md-2"
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>

        <b-button
          class="btn-gradient border-0 px-75 px-md-2"
          pill
          @click="handleClickSubmit(dataTrips, bookingData)"
        >
          <span class="align-middle">Thêm chuyến bay</span>
        </b-button>
      </template>

      <IAmOverlay
        :loading="!dataPriceCalculated ? true : false"
        :spinner-variant="'info'"
      >
        <div v-if="!isEmpty(dataPriceCalculated) && dataPriceCalculated[0].headerInformation.length > 0">
          <span>
            Hành trình hiện tại:
          </span>

          <b-card
            v-for="(trip, tripIndex) in dataTrips"
            :key="tripIndex"
            class="mb-50 border"
            body-class="p-75"
          >
            <div
              v-for="(segment, segmentIndex) in trip"
              :key="segmentIndex"
              :class="segmentIndex > 0 ? 'mt-1' : ''"
            >
              <div
                cols="12"
                class="text-dark fw-800"
                :class="isMobileView ? 'font-small-3' : 'font-medium-1'"
              >
                <span class="mr-75 text-nowrap">{{ `${segment.airline}${segment.flightNumber}` }}</span>
                <span class="mr-75 text-nowrap">{{ `${segment.bookingClass.code}` }}</span>
                <span class="mr-75 text-nowrap">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).dayMonth }}</span>
                <span class="mr-75 text-nowrap">{{ `${segment.departure}${segment.arrival}` }}</span>
                <span class="mr-75 text-nowrap">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).hourMin }}</span>
                <span class="mr-75 text-nowrap">{{ convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).hourMin }}</span>
                <span class="mr-75 text-nowrap">{{ `| ${segment.airCraft}` }}</span>
              </div>
            </div>
          </b-card>

          <b-alert
            variant="success"
            show
            class="px-1 py-50 mt-1 mb-2 font-weight-bold"
          >
            GIÁ VÉ CÓ SỰ THAY ĐỔI HẠNG ĐẶT CHỖ. VUI LÒNG KIỂM TRA!
          </b-alert>

          <div class="mx-50 mb-0 h4 fw-700 text-airline">
            {{ $t('reservation.showCalculatePrice.headerInformationTitle') }}
          </div>

          <b-card
            class="text-airline"
            body-class="p-75"
          >
            <div
              v-for="(newTrip, newTripIndex) in dataPriceCalculated[0].headerInformation"
              :key="newTripIndex"
            >
              <small
                class="fw-700"
                :class="isMobileView ? 'font-small-3' : 'font-medium-1'"
              >
                {{ newTrip }}
              </small>
            </div>
          </b-card>
        </div>

        <b-card
          v-else
          class="mb-1"
          body-class="py-1 px-25"
        >
          <span
            class="text-body"
            :class="isMobileView ? 'font-small-3': 'font-medium-1'"
          >
            Hành trình hiện tại:
          </span>

          <b-card
            v-for="(trip, tripIndex) in dataTrips"
            :key="tripIndex"
            class="mb-50 border"
            body-class="p-75"
          >
            <div
              v-for="(segment, segmentIndex) in trip"
              :key="segmentIndex"
              :class="segmentIndex > 0 ? 'mt-1' : ''"
            >
              <div
                cols="12"
                class="text-body fw-700"
                :class="isMobileView ? 'font-small-4' : 'font-medium-2'"
              >
                <span class="mr-75 text-nowrap">{{ `${segment.airline}${segment.flightNumber}` }}</span>
                <span class="mr-75 text-nowrap">{{ `${segment.bookingClass.code}` }}</span>
                <span class="mr-75 text-nowrap">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).dayMonth }}</span>
                <span class="mr-75 text-nowrap">{{ `${segment.departure}${segment.arrival}` }}</span>
                <span class="mr-75 text-nowrap">{{ convertISODateTime(segment.departureDate, segment.departureTimezone).hourMin }} </span>
                <span class="mr-75 text-nowrap">{{ convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).hourMin }} </span>
                <span class="mr-75 text-nowrap">{{ `| ${segment.airCraft}` }}</span>
              </div>
            </div>
          </b-card>
        </b-card>

        <BTableLite
          bordered
          responsive
          small
          class="mb-1 rounded"
          thead-class="text-dark font-weight-bolder text-nowrap"
          :fields="resultCalcPriceTableColumns"
          :items="dataPriceCalculated"
        >
          <template
            v-for="column in resultCalcPriceTableColumns"
            v-slot:[`head(${column})`]="data"
          >
            <span
              :key="column"
              class="text-dark"
            >
              {{ $t(`reservation.showCalculatePrice.columns.${data.column}`) }}
            </span>
          </template>

          <template #cell(key)="data">
            {{ data.index +1 }}
          </template>

          <template #cell(appliedPassengers)="{item}">
            <div>
              <span class="font-weight-bolder mr-25">
                {{ `${paxLists[item.fareInfos[0].paxType.toLowerCase()]}x` }}
              </span>
              <span class="font-weight-bold">
                {{ item.fareInfos[0].paxType }}
              </span>
            </div>
          </template>
          <template #cell(basePrice)="{item}">
            <div class="text-right fw-600">
              {{ formatCurrency(item.basePrice * `${paxLists[item.fareInfos[0].paxType.toLowerCase()]}`) }}
            </div>
          </template>
          <template #cell(totalTaxes)="{item}">
            <div class="text-right fw-600">
              {{ formatCurrency(item.totalTaxes * `${paxLists[item.fareInfos[0].paxType.toLowerCase()]}`) }}
            </div>
          </template>
          <template #cell(totalPrice)="{item}">
            <div class="text-right text-airline fw-800">
              {{ formatCurrency(item.totalPrice * `${paxLists[item.fareInfos[0].paxType.toLowerCase()]}`) }}
            </div>
          </template>
        </BTableLite>
      </IAmOverlay>
    </b-modal>

    <!-- :selected-trips="dataTrips" -->
    <ModalModifyConfirmAddFlightClassBooking
      v-if="selectedTrips"
      :selected-trips="selectedTrips"
      :booking-data="bookingData"
      :is-cheapest="'-cheapest'"
      :status-segment="statusSegment"
    />

  </div>
</template>

<script>
import {
  BModal,
  BFormCheckbox,
  BButton,
  BCard,
  BFormInput,
  BCol,
  BRow,
  BTableLite,
  BAlert,

} from 'bootstrap-vue'
import {
  ref,
  toRefs,
} from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

import {
  formatCurrency,
  convertISODateTime,
} from '@/@core/utils/filter'
import { getUserData } from '@/api/auth/utils'
import IAmOverlay from '@/components/IAmOverlay.vue'
import { apiBooking } from '@/api/'

import useToast from '@useToast'

import ModalModifyConfirmAddFlightClassBooking from './ModalModifyConfirmAddFlightClassBooking.vue'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BButton,
    BCard,
    BFormInput,
    BCol,
    BRow,
    BTableLite,
    BAlert,

    ModalModifyConfirmAddFlightClassBooking,
    IAmOverlay,
  },
  props: {
    dataTrips: {
      type: Array,
      required: true,
    },
    payloadSearch: {
      type: Object,
      required: true,
    },
    modifyClassBooking: {
      type: Boolean,
      default: true,
    },
    bookingData: {
      type: Object,
      default: () => {},
    },
    statusSegment: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { toastError } = useToast()

    const { dataTrips, payloadSearch } = toRefs(props)
    const loading = ref(false)

    const paxLists = ref(cloneDeep(payloadSearch.value))
    const isBargainFinder = ref(false)

    const dataPriceCalculated = ref(null)
    const selectedTrips = ref(null)

    async function submitRecalculatePrice() {
      const isNotValid = (Number(paxLists.value.adult) + Number(paxLists.value.child) > 9) || (Number(paxLists.value.infant) > Number(paxLists.value.adult))
      if (isNotValid) {
        if (Number(paxLists.value.adult) + Number(paxLists.value.child) > 9) {
          toastError({
            title: 'flight.passengerMore9',
          })
        }

        if (Number(paxLists.value.infant) > Number(paxLists.value.adult)) {
          toastError({
            title: 'Số em bé không được nhiều hơn số lượng hành khách người lớn.',
          })
        }

        return
      }
      try {
        loading.value = true
        dataPriceCalculated.value = null

        const passengerTypeRequests = [
          {
            code: 'ADULT',
            quantity: Number(paxLists.value.adult),
          },
          {
            code: 'CHILD',
            quantity: Number(paxLists.value.child),
          },
          {
            code: 'INFANT',
            quantity: Number(paxLists.value.infant),
          },
        ]

        const payload = {
          contact: getUserData().employeeData.id,
          source: '1S',
          agencyCode: getUserData().employeeData?.agency?.agencyCode,
          paxCode: '',
          tourCode: '',
          accountCode: '',
          bargainFinder: isBargainFinder.value,
          passengerTypeRequests: passengerTypeRequests.filter(i => i.quantity > 0),
          itineraries: dataTrips.value.map(trip => ({
            source: '1S',
            airline: 'VN',
            clientId: '',
            bookingKey: '',
            fareBreakdowns: [],
            segments: trip.map(seg => ({
              leg: seg.leg,
              airline: seg.airline,
              departure: seg.departure,
              arrival: seg.arrival,
              departureTime: convertISODateTime(seg.departureDate, seg.departureTimezone).ISOdatetime,
              arrivalTime: convertISODateTime(seg.arrivalDate, seg.arrivalTimezone).ISOdatetime,
              flightNumber: seg.flightNumber ? String(seg.flightNumber) : '',
              fareType: '',
              fareBasisCode: '',
              bookingClass: seg?.bookingClass?.code || '',
              groupClass: '',
              marriageGrp: '',
              segmentValue: '',
              segmentId: '',
            })),
          })),
        }

        const response = await apiBooking.calculatePrice(payload)

        if (response) {
          dataPriceCalculated.value = response
        }
      } catch (error) {
        console.error('An error occurred:', error)
      } finally {
        loading.value = false

        if (dataPriceCalculated.value) {
          this.$bvModal.show('modal-modify-booking-recalculate-show-price-result')
        }
      }
    }

    async function handleClickSubmit(dataTrips, bookingData) {
      selectedTrips.value = null

      if (dataTrips.some(item => item === null)) {
        toastError({
          title: 'Chưa chọn chuyến bay!',
        })
      } else if (isEmpty(bookingData)) {
        toastError({
          title: 'Không có thông tin booking cũ!',
        })
      } else {
        await new Promise(resolve => {
          if (isBargainFinder.value) {
            let segmentCount = 0
            selectedTrips.value = dataTrips.map(trip => {
              const data = trip.map(seg => ({
                ...seg,
                bookingClass: {
                  ...seg.bookingClass,
                  code: dataPriceCalculated.value[0].bookingInfos[segmentCount].bookingClass,
                },
              }))

              segmentCount += 1
              return data
            })
          } else {
            selectedTrips.value = dataTrips
          }

          resolve()
        })

        this.$bvModal.show('modal-modify-class-booking-add-flight-confirm-cheapest')
      }
    }

    function handleShowModal() {
      isBargainFinder.value = false
      paxLists.value = cloneDeep(props.payloadSearch)
    }

    function handleHideModalShowPrice() {
      dataPriceCalculated.value = null
    }

    const paxListsColumns = [{ label: '', key: 'checkbox' }, { label: 'paxId', key: 'pax_id' }, { label: 'paxType', key: 'pax_type' }, { label: 'fullName', key: 'full_name' }]
    const resultCalcPriceTableColumns = ['key', 'appliedPassengers', 'basePrice', 'totalTaxes', 'totalPrice']

    return {
      paxLists,
      paxListsColumns,
      resultCalcPriceTableColumns,
      loading,
      selectedTrips,
      dataPriceCalculated,
      isBargainFinder,

      isEmpty,
      handleClickSubmit,
      formatCurrency,
      submitRecalculatePrice,
      convertISODateTime,

      handleShowModal,
      handleHideModalShowPrice,
    }
  },
}
</script>

<style lang="scss" scoped>
.margin-checkbox-label::v-deep {
  .custom-control-label {
    // vertical-align: center !important;
    margin-top: 2px !important;
  }
}
</style>
