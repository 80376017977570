var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-modify-result-recalculate-price-class-booking",
      "title": _vm.$t('reservation.recalculatePriceFlight'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-50",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100 mr-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient mt-lg-25  border-0 px-2",
          attrs: {
            "pill": "",
            "disabled": _vm.loading
          },
          on: {
            "click": _vm.submitRecalculatePrice
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" Tính giá ")])])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading,
      "spinner-variant": 'info'
    }
  }, [_c('b-card', {
    attrs: {
      "body-class": "py-1 px-25 px-md-1"
    }
  }, [_c('div', {
    staticClass: "mb-25"
  }, [_vm._v(" Hành trình: ")]), _vm.dataTrips ? _c('div', _vm._l(_vm.dataTrips, function (trip, tripIndex) {
    return _c('b-card', {
      key: tripIndex,
      staticClass: "border",
      attrs: {
        "body-class": "p-75"
      }
    }, _vm._l(trip, function (segment, segmentIndex) {
      return _c('b-row', {
        key: segmentIndex,
        class: segmentIndex > 0 ? 'mt-1' : '',
        attrs: {
          "no-gutters": ""
        }
      }, [_c('b-col', {
        staticClass: "text-dark font-weight-bold font-small-4",
        attrs: {
          "cols": "12"
        }
      }, [_c('p', {
        staticClass: "font-weight-bolder mb-0"
      }, [_c('span', {
        staticClass: "mr-50 text-nowrap font-medium-1"
      }, [_vm._v(_vm._s("".concat(segment.airline).concat(segment.flightNumber)))]), _c('span', {
        staticClass: "mr-50 text-nowrap font-medium-1"
      }, [_vm._v(_vm._s("".concat(segment.bookingClass.code)))]), _c('span', {
        staticClass: "mr-50 text-nowrap font-medium-1"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departureDate, segment.departureTimezone).dayMonth))]), _c('span', {
        staticClass: "mr-50 text-nowrap font-medium-1"
      }, [_vm._v(_vm._s("".concat(segment.departure).concat(segment.arrival)))]), _c('span', {
        staticClass: "mr-1 text-nowrap font-medium-1"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departureDate, segment.departureTimezone).hourMin) + " " + _vm._s(_vm.convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).hourMin))]), _c('span', {
        staticClass: "text-nowrap font-medium-1"
      }, [_vm._v(_vm._s("".concat(segment.airCraft)))])])])], 1);
    }), 1);
  }), 1) : _vm._e()]), _vm.paxLists ? _c('b-card', [_c('div', {
    staticClass: "mb-1"
  }, [_vm._v(" Số lượng hành khách: ")]), _c('div', {
    staticClass: "d-flex align-items-center mb-50"
  }, [_c('span', {
    staticClass: "mr-50",
    staticStyle: {
      "min-width": "90px"
    }
  }, [_vm._v(" Người lớn: ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-input', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars"
    }],
    staticClass: "mx-75 font-weight-bolder font-medium-3",
    staticStyle: {
      "max-width": "50px"
    },
    attrs: {
      "maxlength": 1,
      "disabled": _vm.modifyClassBooking
    },
    model: {
      value: _vm.paxLists.adult,
      callback: function callback($$v) {
        _vm.$set(_vm.paxLists, "adult", $$v);
      },
      expression: "paxLists.adult"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex align-items-center mb-50"
  }, [_c('span', {
    staticClass: "mr-50",
    staticStyle: {
      "min-width": "90px"
    }
  }, [_vm._v(" Trẻ em: ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-input', {
    staticClass: "mx-75 font-weight-bolder font-medium-3",
    staticStyle: {
      "max-width": "50px"
    },
    attrs: {
      "maxlength": 1,
      "disabled": _vm.modifyClassBooking
    },
    model: {
      value: _vm.paxLists.child,
      callback: function callback($$v) {
        _vm.$set(_vm.paxLists, "child", $$v);
      },
      expression: "paxLists.child"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex align-items-center mb-50"
  }, [_c('span', {
    staticClass: "mr-50",
    staticStyle: {
      "min-width": "90px"
    }
  }, [_vm._v(" Em bé: ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-input', {
    staticClass: "mx-75 font-weight-bolder font-medium-3",
    staticStyle: {
      "max-width": "50px"
    },
    attrs: {
      "maxlength": 1,
      "disabled": _vm.modifyClassBooking
    },
    model: {
      value: _vm.paxLists.infant,
      callback: function callback($$v) {
        _vm.$set(_vm.paxLists, "infant", $$v);
      },
      expression: "paxLists.infant"
    }
  })], 1)])]) : _vm._e(), _c('b-card', {
    attrs: {
      "body-class": "py-1"
    }
  }, [_c('div', {
    staticClass: "margin-checkbox-label d-flex-center justify-content-around gap-2 text-center"
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "name": "retain-checkbox"
    },
    model: {
      value: _vm.isBargainFinder,
      callback: function callback($$v) {
        _vm.isBargainFinder = $$v;
      },
      expression: "isBargainFinder"
    }
  }, [_c('span', {
    staticClass: "mb-0 font-weight-bold",
    class: _vm.isBargainFinder ? 'text-success' : 'text-dark'
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bargainFinder')) + " ")])])], 1)])], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-modify-booking-recalculate-show-price-result",
      "title": _vm.$t('reservation.showCalculatePrice.title'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "body-class": "px-1",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "xl"
    },
    on: {
      "hide": _vm.handleHideModalShowPrice
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "rounded-pill px-1 mx-50 mr-md-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('b-button', {
          staticClass: "btn-gradient border-0 px-75 px-md-2",
          attrs: {
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return _vm.handleClickSubmit(_vm.dataTrips, _vm.bookingData);
            }
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v("Thêm chuyến bay")])])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": !_vm.dataPriceCalculated ? true : false,
      "spinner-variant": 'info'
    }
  }, [!_vm.isEmpty(_vm.dataPriceCalculated) && _vm.dataPriceCalculated[0].headerInformation.length > 0 ? _c('div', [_c('span', [_vm._v(" Hành trình hiện tại: ")]), _vm._l(_vm.dataTrips, function (trip, tripIndex) {
    return _c('b-card', {
      key: tripIndex,
      staticClass: "mb-50 border",
      attrs: {
        "body-class": "p-75"
      }
    }, _vm._l(trip, function (segment, segmentIndex) {
      return _c('div', {
        key: segmentIndex,
        class: segmentIndex > 0 ? 'mt-1' : ''
      }, [_c('div', {
        staticClass: "text-dark fw-800",
        class: _vm.isMobileView ? 'font-small-3' : 'font-medium-1',
        attrs: {
          "cols": "12"
        }
      }, [_c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s("".concat(segment.airline).concat(segment.flightNumber)))]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s("".concat(segment.bookingClass.code)))]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departureDate, segment.departureTimezone).dayMonth))]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s("".concat(segment.departure).concat(segment.arrival)))]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departureDate, segment.departureTimezone).hourMin))]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).hourMin))]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s("| ".concat(segment.airCraft)))])])]);
    }), 0);
  }), _c('b-alert', {
    staticClass: "px-1 py-50 mt-1 mb-2 font-weight-bold",
    attrs: {
      "variant": "success",
      "show": ""
    }
  }, [_vm._v(" GIÁ VÉ CÓ SỰ THAY ĐỔI HẠNG ĐẶT CHỖ. VUI LÒNG KIỂM TRA! ")]), _c('div', {
    staticClass: "mx-50 mb-0 h4 fw-700 text-airline"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.showCalculatePrice.headerInformationTitle')) + " ")]), _c('b-card', {
    staticClass: "text-airline",
    attrs: {
      "body-class": "p-75"
    }
  }, _vm._l(_vm.dataPriceCalculated[0].headerInformation, function (newTrip, newTripIndex) {
    return _c('div', {
      key: newTripIndex
    }, [_c('small', {
      staticClass: "fw-700",
      class: _vm.isMobileView ? 'font-small-3' : 'font-medium-1'
    }, [_vm._v(" " + _vm._s(newTrip) + " ")])]);
  }), 0)], 2) : _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "body-class": "py-1 px-25"
    }
  }, [_c('span', {
    staticClass: "text-body",
    class: _vm.isMobileView ? 'font-small-3' : 'font-medium-1'
  }, [_vm._v(" Hành trình hiện tại: ")]), _vm._l(_vm.dataTrips, function (trip, tripIndex) {
    return _c('b-card', {
      key: tripIndex,
      staticClass: "mb-50 border",
      attrs: {
        "body-class": "p-75"
      }
    }, _vm._l(trip, function (segment, segmentIndex) {
      return _c('div', {
        key: segmentIndex,
        class: segmentIndex > 0 ? 'mt-1' : ''
      }, [_c('div', {
        staticClass: "text-body fw-700",
        class: _vm.isMobileView ? 'font-small-4' : 'font-medium-2',
        attrs: {
          "cols": "12"
        }
      }, [_c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s("".concat(segment.airline).concat(segment.flightNumber)))]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s("".concat(segment.bookingClass.code)))]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departureDate, segment.departureTimezone).dayMonth))]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s("".concat(segment.departure).concat(segment.arrival)))]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departureDate, segment.departureTimezone).hourMin) + " ")]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).hourMin) + " ")]), _c('span', {
        staticClass: "mr-75 text-nowrap"
      }, [_vm._v(_vm._s("| ".concat(segment.airCraft)))])])]);
    }), 0);
  })], 2), _c('BTableLite', {
    staticClass: "mb-1 rounded",
    attrs: {
      "bordered": "",
      "responsive": "",
      "small": "",
      "thead-class": "text-dark font-weight-bolder text-nowrap",
      "fields": _vm.resultCalcPriceTableColumns,
      "items": _vm.dataPriceCalculated
    },
    scopedSlots: _vm._u([_vm._l(_vm.resultCalcPriceTableColumns, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column,
            staticClass: "text-dark"
          }, [_vm._v(" " + _vm._s(_vm.$t("reservation.showCalculatePrice.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "cell(key)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(appliedPassengers)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_c('span', {
          staticClass: "font-weight-bolder mr-25"
        }, [_vm._v(" " + _vm._s("".concat(_vm.paxLists[item.fareInfos[0].paxType.toLowerCase()], "x")) + " ")]), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.fareInfos[0].paxType) + " ")])])];
      }
    }, {
      key: "cell(basePrice)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-right fw-600"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.basePrice * "".concat(_vm.paxLists[item.fareInfos[0].paxType.toLowerCase()]))) + " ")])];
      }
    }, {
      key: "cell(totalTaxes)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-right fw-600"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalTaxes * "".concat(_vm.paxLists[item.fareInfos[0].paxType.toLowerCase()]))) + " ")])];
      }
    }, {
      key: "cell(totalPrice)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-right text-airline fw-800"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPrice * "".concat(_vm.paxLists[item.fareInfos[0].paxType.toLowerCase()]))) + " ")])];
      }
    }], null, true)
  })], 1)], 1), _vm.selectedTrips ? _c('ModalModifyConfirmAddFlightClassBooking', {
    attrs: {
      "selected-trips": _vm.selectedTrips,
      "booking-data": _vm.bookingData,
      "is-cheapest": '-cheapest',
      "status-segment": _vm.statusSegment
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }